import React, { useState } from 'react';
import { X, Calendar, MessageSquare, Paperclip } from 'lucide-react';
import styles from './TaskDetailsModal.module.css';
import CheckList from './CheckList';

const TaskDetailsModal = ({ task, columns, updateTask, deleteTask, onClose }) => {
  const [editedTask, setEditedTask] = useState(task);
  const [newFiles, setNewFiles] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedTask(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedTask = {
      ...editedTask,
      attachments: [...(editedTask.attachments || []), ...newFiles.map(file => file.name)]
    };
    updateTask(updatedTask);
    onClose();
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this task?')) {
      deleteTask(task.id);
      onClose();
    }
  };

  const handleFileChange = (e) => {
    setNewFiles([...newFiles, ...Array.from(e.target.files)]);
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>Task Details</h2>
          <button onClick={onClose} className={styles.closeButton}>
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Task Name</label>
            <input
              id="name"
              type="text"
              name="name"
              value={editedTask.name || ''}
              onChange={handleChange}
              placeholder="Task name"
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={editedTask.description || ''}
              onChange={handleChange}
              placeholder="Task description"
              className={styles.input}
              rows={3}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              value={editedTask.status}
              onChange={handleChange}
              className={styles.input}
            >
              {columns.map((column) => (
                <option key={column.id} value={column.id}>
                  {column.title}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="tags">Tags</label>
            <input
              id="tags"
              type="text"
              name="tags"
              value={editedTask.tags || ''}
              onChange={handleChange}
              placeholder="Tags (comma-separated)"
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="assignee">Assigned To</label>
            <input
              id="assignee"
              type="text"
              name="assignee"
              value={editedTask.owner.username}
              onChange={(e) => setEditedTask(prev => ({ ...prev, owner: { ...prev.owner, username: e.target.value } }))}
              placeholder="Assign to (username)"
              className={styles.input}
            />
          </div>
          <CheckList />

          <div className={styles.metaInfo}>
            <div className={styles.metaItem}>
              <Calendar size={14} className={styles.icon} />
              Created: {new Date(editedTask.createdAt).toLocaleString()}
            </div>
            <div className={styles.metaItem}>
              <Calendar size={14} className={styles.icon} />
              Updated: {new Date(editedTask.updatedAt).toLocaleString()}
            </div>
            <div className={styles.metaItem}>
              <MessageSquare size={14} className={styles.icon} />
              Threads: {editedTask.threads.count}
            </div>
          </div>
          <div className={styles.attachmentSection}>
            <label className={styles.attachmentLabel}>
              <Paperclip size={14} className={styles.icon} />
              Attachments
            </label>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className={styles.fileInput}
            />
            {editedTask.attachments && editedTask.attachments.length > 0 && (
              <ul className={styles.attachmentList}>
                {editedTask.attachments.map((attachment, index) => (
                  <li key={index}>{attachment}</li>
                ))}
              </ul>
            )}
            {newFiles.length > 0 && (
              <div>
                <p>New files to be added:</p>
                <ul>
                  {newFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className={styles.buttonGroup}>
            <button type="button" onClick={handleDelete} className={styles.deleteButton}>
              Delete
            </button>
            <button type="submit" className={styles.saveButton}>
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TaskDetailsModal;