import React, { useState } from 'react';
import styles from './AddColumnModal.module.css';


const AddColumnModal= ({ addColumn, isOpen, onClose }) => {
  const [title, setTitle] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title.trim()) {
      addColumn({ title: title.trim() });
      setTitle('');
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Introduzca el título de la lista..."
            className={styles.input}
          />
          <div className={styles.buttonGroup}>
            <button type="submit" className={styles.submitButton}>
              Añadir lista
            </button>
            <button onClick={onClose} className={styles.closeButton}>
             <p>Cerrar</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddColumnModal;