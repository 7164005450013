import { pusher } from "../../../utils/pusher";
import Box from "@mui/material/Box";
import React, { useEffect, useContext } from "react";
import Header from "../../../components/global/Header";
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
  GridLogicOperator,
  gridFilteredTopLevelRowCountSelector,
} from "@mui/x-data-grid-pro";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { fetchData } from "../../../utils/ApiCall";
import { UserContext } from "../../../UserContext";
import { Alert, LinearProgress } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useLocation } from "react-router-dom";
import CustomToolBar from "../../../components/global/CustomToolBar.jsx";
import {
  boardStyles,
  closedBoardStyles,
  getTableStyles,
} from "../../../json/BoardStyles.js";
import CheckboxRow from "../RowDelete/CheckboxRow";
import ModalRowDelete from "../RowDelete/ModalRowDelete";
import { getGroupById } from "../../../../src/utils/ApiCall.js";

const eliminarTagsHTML = (textoHTML) => {
  // Crea un elemento HTML temporal (div) para contener el texto HTML
  var temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = textoHTML;

  // Retorna solo el texto sin los tags HTML
  let txt = temporalDiv.textContent || temporalDiv.innerText;
  txt = txt.replace(/\[object Object\]/g, "");
  var expresionRegularNbsp = new RegExp(String.fromCharCode(160), "g");

  // Reemplaza todos los NO-BREAK SPACE con espacios normales
  txt = txt.replace(expresionRegularNbsp, " ");
  return txt;
};

const BaseBoard = ({
  columns,
  groups,
  getGroupRows,
  setGroupItems,
  apiUrl,
  status,
  setStatus,
  controlEdit,
  refreshState,
  updateBoard,
  updateRows,
  viewFilter,
  setViewFilter,
  columnsFilter,
  setColumnsFilter,
  pinnedColumns,
  setPinnedColumns,
  setSelectionRow,
  selectionRow,
  selectedView,
  checkboxSelection,
  height,
  maxHeight,
  isLoadingRows,
}) => {
  const location = useLocation();
  const apiRef = useGridApiRef();
  const searchParams = new URLSearchParams(location.search);
  const pathname = location.pathname;
  const [windowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 600;
  const [deleteInput, setDeleteInput] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { key, setKey } = useContext(UserContext);
  const updateValue = searchParams.get("update");
  const [updateParams] = useState(updateValue);
  const [copiedData, setCopiedData] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fixedRowHeight = 40;
  const [position, setPosition] = useState([0]);
  const [quickFilter, setQuickFilter] = useState([]);
  const visibleGroupIndex = 0;
  const [hideGroup, setHideGroup] = useState({
    ...groups.map((groupName, idx) => {
      if (idx === visibleGroupIndex) {
        return { hide: false };
      }
      return { hide: true };
    }),
  });

  setKey(localStorage?.getItem("jwt"));

  useEffect(() => {
    const channel = pusher.subscribe(`${pathname.split("/")[1]}-prod`);
    if (channel.bind) {
      channel.bind("refresh", async function () {
        if (window.modalThreadOpen) {
          return;
        }
        if (apiUrl) {
          const data = await fetchData(apiUrl);
          setGroupItems(data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.modalThreadOpen) {
      return;
    }

    const fetchApiInfo = async () => {
      try {
        if (key && apiUrl) {
          const data = await fetchData(apiUrl);
          setGroupItems(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDataAndUpdate = async () => {
      await fetchApiInfo();
    };

    fetchDataAndUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshState, status, updateRows]);

  useEffect(() => {
    if (quickFilter?.length > 0) {
      openAllGroups(groups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickFilter]);

  useEffect(() => {
    if (updateParams) {
      const setUpdateLocation = async (rowID) => {
        let statusRow = await getGroupById(rowID, pathname.replace("/", ""));
        window.location.href = `#${statusRow}`;
        const groupsToLowerCase = groups.map((elemento) =>
          elemento.toLowerCase()
        );
        const indexGroup = groupsToLowerCase.indexOf(statusRow.toLowerCase());
        setPosition([indexGroup]);
        setHideGroup({ ...hideGroup, [indexGroup]: { hide: false } });
      };
      setUpdateLocation(updateParams);
      // openAllGroups(groups)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateParams, pathname]);

  const openAllGroups = (groups) => {
    setHideGroup({
      ...groups.map((table, idx) => {
        return { hide: false };
      }),
    });
    setPosition(
      groups.map((table, idx) => {
        return idx;
      })
    );
  };

  const handleSelectionModelChange = (selectionModel) => {
    setSelectionRow(selectionModel);
    setIsModalOpen(selectionModel?.length > 0);
  };

  const handleHide = (e, index, hideIndex) => {
    e.preventDefault();
    let newHide = {};
    for (const [key, value] of Object.entries(hideGroup)) {
      if (+key === +index) {
        newHide[key] = { hide: !hideIndex };
      } else {
        newHide[key] = value;
      }
    }
    setHideGroup(newHide);
    if (position.includes(index)) {
      setPosition(position.filter((e) => e !== index));
    } else {
      setPosition([...position, index]);
    }
  };

  const handleGetTable = (table) => {
    setStatus(table);
  };

  return (
    <div style={{ padding: isMobile ? "5px 10px" : "0px" }}>
      <Header title="" />

      {groups.map((groupName, index) => (
        <div key={index} id={groupName}>
          <CheckboxRow
            isModalOpen={isModalOpen}
            selectionRow={selectionRow}
            setDeleteInput={setDeleteInput}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            isDeleteModalOPen={isDeleteModalOpen}
          />
          <ModalRowDelete
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            isDeleteModalOpen={isDeleteModalOpen}
            setDeleteInput={setDeleteInput}
            deleteInput={deleteInput}
            selectionRow={selectionRow}
            pathname={pathname}
            updateBoard={updateBoard}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "flex-start" : "space-between",
              marginBottom: "8px",
            }}
          >
            <h3 style={{ color: getTableStyles(groupName).color }}>
              {groupName}
            </h3>
            <Box
              display={isMobile ? "flex" : "none"}
              alignItems="center"
              marginRight="25px"
              onClick={(e) => handleHide(e, index, hideGroup[`${index}`].hide)}
            >
              {position.includes(index) &&
              hideGroup[`${index}`]?.hide === false ? (
                <ArrowBackIos
                  style={{
                    transform: "rotate(270deg)",
                    marginTop: isMobile ? "-10px" : "12px",
                    marginLeft: isMobile ? "10px" : "0px",
                    cursor: "pointer",
                    color: getTableStyles(groupName).color,
                  }}
                />
              ) : (
                <ArrowForwardIosIcon
                  onClick={() => handleGetTable(groupName)}
                  style={{
                    marginTop: "2px",
                    marginLeft: "10px",
                    fontSize: "20px",
                    cursor: "pointer",
                    color: getTableStyles(groupName).color,
                  }}
                />
              )}
            </Box>
          </div>

          <Box
            display="flex"
            width="100%"
            alignItems="flex-start"
            marginBottom="30px"
          >
            <Box
              display={isMobile ? "none" : "flex"}
              alignItems="center"
              marginRight="25px"
              onClick={(e) => handleHide(e, index, hideGroup[`${index}`]?.hide)}
            >
              {position.includes(index) &&
              hideGroup[`${index}`]?.hide === false ? (
                <ArrowBackIos
                  style={{
                    transform: "rotate(270deg)",
                    marginTop: "12px",
                    cursor: "pointer",
                    color: getTableStyles(groupName).color,
                  }}
                />
              ) : (
                <ArrowForwardIosIcon
                  onClick={() => handleGetTable(groupName)}
                  style={{
                    marginTop: "18px",
                    cursor: "pointer",
                    color: getTableStyles(groupName).color,
                  }}
                />
              )}
            </Box>

            {position.includes(index) &&
            hideGroup[`${index}`]?.hide === false ? (
              <Box
                m="0px 0 0 0"
                height={height}
                maxHeight={maxHeight}
                width="100%"
                bgcolor="#fefcfd"
                overflow="hidden"
                sx={boardStyles}
                style={{
                  borderLeft: getTableStyles(groupName).border,
                }}
              >
                <DataGridPro
                  rows={getGroupRows(groupName)}
                  rowHeight={fixedRowHeight}
                  headerHeight={40}
                  columns={columns}
                  getRowId={(row) => row.id ?? row.offerID}
                  style={{
                    position: "relative",
                    maxWidth: "100%",
                    height: isLoadingRows ? "175px" : "100%",
                  }}
                  columnBuffer={1}
                  columnThreshold={1}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                  loading={isLoadingRows ? true : false}
                  filterModel={
                    updateParams
                      ? {
                          items: [
                            {
                              id: 1,
                              field: "id",
                              operator: "equals",
                              value: updateParams,
                            },
                          ],
                          quickFilterLogicOperator: GridLogicOperator.Or,
                          quickFilterExcludeHiddenColumns: true,
                        }
                      : viewFilter
                  }
                  onFilterModelChange={(newFilterModel) => {
                    setQuickFilter(newFilterModel?.quickFilterValues);
                    setViewFilter(newFilterModel);
                  }}
                  slots={{
                    toolbar: () => (
                      <CustomToolBar
                        apiRef={apiRef}
                        pathname={pathname}
                        updateBoard={updateBoard}
                        selectedView={selectedView}
                      />
                    ),
                    loadingOverlay: () => <LinearProgress />,
                  }}
                  columnVisibilityModel={columnsFilter}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnsFilter(newModel)
                  }
                  pinnedColumns={pinnedColumns}
                  onPinnedColumnsChange={(newModel) =>
                    setPinnedColumns(newModel)
                  }
                  onCellEditStop={(params) => {
                    controlEdit(params);
                  }}
                  disableRowSelectionOnClick
                  checkboxSelection={checkboxSelection}
                  initialState={{
                    pinnedColumns: {
                      left: [
                        GRID_CHECKBOX_SELECTION_COL_DEF.field,
                        ...columns.map((column) => column.field).slice(0, 3),
                      ],
                    },
                  }}
                  apiRef={apiRef}
                  onRowSelectionModelChange={handleSelectionModelChange}
                  onClipboardCopy={(copiedString) => {
                    copiedString = eliminarTagsHTML(copiedString);
                    navigator.clipboard.writeText(copiedString);
                    setCopiedData(copiedString);
                    setSnackbar({
                      children: `Data copiada`,
                      severity: "success",
                    });
                  }}
                />

                {!!snackbar && copiedData && (
                  <Snackbar
                    open
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}
              </Box>
            ) : (
              <Box
                height="6.3vh"
                width="100%"
                bgcolor="#fefcfd"
                overflow="hidden"
                sx={closedBoardStyles}
                style={{
                  borderLeft: getTableStyles(groupName).border,
                }}
              >
                <DataGridPro
                  rows={[]}
                  columns={[]}
                  style={{ position: "relative", maxWidth: "100%" }}
                />
              </Box>
            )}
          </Box>
        </div>
      ))}
    </div>
  );
};

export default BaseBoard;
