import React, { useState, useEffect } from 'react';
import { Check, Plus, EyeOff, Trash2 } from 'lucide-react';
import styles from './CheckList.module.css';



const CheckList = () => {
    const [items, setItems] = useState([]);
    const [newItemText, setNewItemText] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        updateProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const addItem = (e) => {
        e.preventDefault()
        if (newItemText.trim() !== '') {
            setItems([...items, { id: Date.now(), text: newItemText, checked: false }]);
            setNewItemText('');
        }
    };

    const toggleItem = (e, id) => {
        e.preventDefault()
        setItems(items.map(item =>
            item.id === id ? { ...item, checked: !item.checked } : item
        ));
    };

    const removeItem = (id) => {
        setItems(items.filter(item => item.id !== id));
    };

    const updateProgress = () => {
        const checkedCount = items.filter(item => item.checked).length;
        const totalCount = items.length;
        setProgress(totalCount > 0 ? (checkedCount / totalCount) * 100 : 0);
    };

    const hideCheckedItems = () => {
        setItems(items.filter(item => !item.checked));
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>Checklist</h2>
                <div className={styles.buttonGroup}>
                    <button
                        onClick={hideCheckedItems}
                        className={styles.iconButton}
                        title="Ocultar elementos marcados"
                    >
                        <EyeOff size={20} />
                    </button>
                    <button
                        onClick={() => setItems([])}
                        className={styles.iconButton}
                        title="Eliminar todo"
                    >
                        <Trash2 size={20} />
                    </button>
                </div>
            </div>
            <div className={styles.progressContainer}>
                <div className={styles.progressBar}>
                    <div
                        className={styles.progressFill}
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
                <p className={styles.progressText}>{progress.toFixed(0)}% completado</p>
            </div>
            <ul className={styles.list}>
                {items.map(item => (
                    <li key={item.id} className={styles.listItem}>
                        <button
                            onClick={(e) => toggleItem(e, item.id)}
                            className={`${styles.checkbox} ${item.checked ? styles.checked : ''}`}
                        >
                            {item.checked && <Check size={16} />}
                        </button>
                        <span className={`${styles.itemText} ${item.checked ? styles.checked : ''}`}>
                            {item.text}
                        </span>
                        <button
                            onClick={() => removeItem(item.id)}
                            className={styles.removeButton}
                        >
                            <Trash2 size={16} />
                        </button>
                    </li>
                ))}
            </ul>
            <div className={styles.inputContainer}>
                <input
                    type="text"
                    value={newItemText}
                    onChange={(e) => setNewItemText(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && addItem(e)}
                    placeholder="Añade un elemento"
                    className={styles.input}
                />
                <button
                    onClick={(e) => addItem(e)}
                    className={styles.addButton}
                >
                    <Plus size={20} />
                </button>
            </div>
        </div>
    );
};

export default CheckList;