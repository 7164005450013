import React from 'react';
import KanbanColumn from './KanbanColumn';
import styles from './KanbanBoard.module.css';

const KanbanBoard = ({
  tasks,
  columns,
  setTasks,
  updateTask,
  deleteTask,
  openTaskDetails,
  setActiveColumn,
  setIsAddTaskModalOpen,
  setColumns,
  pathname,
  updateBoard
}) => {
  const onDragStart = (e, taskId) => {
    e.dataTransfer.setData('text/plain', taskId.toString());
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, columnId) => {
    e.preventDefault();
    const taskId = parseInt(e.dataTransfer.getData('text/plain'), 10);
    const updatedTasks = tasks.map(task =>
      task.id === taskId ? { ...task, status: columnId } : task
    );
    setTasks(updatedTasks);
  };

  function moveItemForward(array, id) {
    const newArray = [...array];
    const index = newArray.findIndex(item => item.id === id);
    if (index === -1) return newArray;
    const [item] = newArray.splice(index, 1);
    if (index === newArray.length) {
      newArray.unshift(item);
    } else {
      newArray.splice(index + 1, 0, item);
    }
    setColumns(newArray);
  }

  function moveItemBack(array, id) {
    const newArray = [...array];
    const index = newArray.findIndex(item => item.id === id);
    if (index === -1) return newArray;
    const [item] = newArray.splice(index, 1);
    if (index === 0) {
      newArray.push(item);
    } else {
      newArray.splice(index - 1, 0, item);
    }
    setColumns(newArray);
  }

  return (
    <div className={styles.board}>
      {columns.map((column) => (
        <div key={column.id}>
          <button onClick={() => moveItemBack(columns, column.id)}>{"<<"}</button>
          <button onClick={() => moveItemForward(columns, column.id)}>{">>"}</button>

          <KanbanColumn
            key={column.id}
            column={column}
            tasks={tasks.filter((task) => task.status === column.id)}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            updateTask={updateTask}
            deleteTask={deleteTask}
            openTaskDetails={openTaskDetails}
            setActiveColumn={setActiveColumn}
            setIsAddTaskModalOpen={setIsAddTaskModalOpen}
            pathname={pathname}
            updateBoard={updateBoard}

          />
        </div>
      ))}
    </div>
  );
};

export default KanbanBoard;