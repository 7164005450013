import React, { useState } from 'react';
import { X, Paperclip } from 'lucide-react';
import styles from './AddTaskModal.module.css';

const AddTaskModal = ({ addTask, columns, isOpen, onClose, activeColumn }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(activeColumn || columns[0].id);
  const [tags, setTags] = useState('');
  const [assignee, setAssignee] = useState('');
  const [files, setFiles] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      const newTask = {
        name: name.trim(),
        description,
        status,
        tags: tags.trim(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        publishedAt: new Date().toISOString(),
        threads: { count: 0 },
        owner: {
          id: 1,
          username: assignee || 'Unassigned',
          email: 'user@example.com',
          role: { id: 1, name: 'Authenticated' }
        },
        attachments: files.map(file => file.name)
      };
      addTask(newTask);
      resetForm();
      onClose();
    }
  };

  const resetForm = () => {
    setName('');
    setDescription('');
    setStatus(activeColumn || columns[0].id);
    setTags('');
    setAssignee('');
    setFiles([]);
  };

  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>Add New Task</h2>
          <button onClick={onClose} className={styles.closeButton}>
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Task name"
            className={styles.input}
          />
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            className={styles.input}
            rows={3}
          />
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className={styles.input}
          >
            {columns.map((column) => (
              <option key={column.id} value={column.id}>
                {column.title}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="Tags (comma-separated)"
            className={styles.input}
          />
          <input
            type="text"
            value={assignee}
            onChange={(e) => setAssignee(e.target.value)}
            placeholder="Assign to (username)"
            className={styles.input}
          />
          <div className={styles.fileUpload}>
            <label htmlFor="file-upload" className={styles.fileUploadLabel}>
              <Paperclip size={20} />
              Attach Files
            </label>
            <input
              id="file-upload"
              type="file"
              multiple
              onChange={handleFileChange}
              className={styles.fileInput}
            />
          </div>
          {files.length > 0 && (
            <div className={styles.fileList}>
              <p>Attached files:</p>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
          <div className={styles.buttonGroup}>
            <button
              type="button"
              onClick={() => {
                resetForm();
                onClose();
              }}
              className={styles.cancelButton}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={styles.submitButton}
            >
              Add Task
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTaskModal;