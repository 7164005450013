import React, { useContext, useEffect } from "react";
import container from "../../../../styles/Global.module.css";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { useState } from "react";
import {
  editOfferAffise,
  editBoardField,
  postCapAffise,
  getStatus,
  duplicateRowsMasterBranding,
} from "../../../../utils/ApiCall";
import Threads from "../../../Threads/Threads";
import { UserContext } from "../../../../UserContext";
import { Currencies } from "../../../../json/Currencies";
import { Button, ThemeProvider, Tooltip, createTheme } from "@mui/material";
import Cap from "../../../../components/aluna/Cap.jsx";
import Duration from "../../../../components/global/Duration.jsx";
import { offerStatusMasterBranding } from "../../../../json/StatusOptions";
import { useLocation } from "react-router-dom";
import ColumnStatus from "../../../../components/global/ColumnStatus";
import EditableSelect from "../../../../components/global/EditableSelect.jsx";
import {
  formatos,
  meses,
  plataformas,
  testOptions,
  tiposContratacion,
} from "../../../../json/CampaignCreator.js";
import EditableText from "../../../../components/global/EditableText.jsx";
import EditableSelectMultiple from "../../../../components/global/EditableSelectMultiple";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import SingleUserSelect from "../../../../components/global/SingleUserSelect";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const AlunaMasterBranding = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [status, setStatus] = useState("Offer ID");
  const apiUrl = `${backendUrl}/api/master-branding/items`;
  const apiUsers = `${backendUrl}/api/users`;
  const [offerID, setOfferID] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [payoutAceptado] = useState("");
  const [cap, setCap] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [capMensual] = useState("");
  const [revenueMonedaAnunciante] = useState("");
  const [windowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 600;
  const [posting, setPosting] = useState(false);

  const [groups] = useState(["Offer ID", "Completed"]);
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "offerID", "threads", "offerTitle"],
    right: [],
  });

  const [position, setPosition] = useState([0]);
  const [selectedView] = useState("tabla-principal");
  const [refreshState, setRefreshState] = useState(false);
  const { key, setKey } = useContext(UserContext);
  const [selectionRow, setSelectionRow] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);
  const [users, setUsers] = useState([]);

  setKey(localStorage?.getItem("jwt"));

  useEffect(() => {
    const fetchApi = async () => {
      const resUsers = await fetch(apiUsers);
      const data = await resUsers.json();

      setUsers(data);
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function OptionsFromColumns(array, attribute) {
    const valoresUnicos = new Set();
    for (const obj of array) {
      const valor = obj[attribute];
      if (valor !== undefined) {
        valoresUnicos.add(valor);
      }
    }
    return Array.from(valoresUnicos);
  }

  const filterByStatus = (rows, status) => {
    const filters = {
      "Offer ID": (item) => item.offerStatus !== "COMPLETED",
      Completed: (item) => item.offerStatus === "COMPLETED",
    };

    return rows.filter(filters[status]);
  };

  const filterItemsAndSetGroups = async (items) => {
    setOfferID(filterByStatus(items, "Offer ID"));
    setCompleted(filterByStatus(items, "Completed"));
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    let status;
    if (field !== "offerStatus") {
      status = await getStatus(rowID, "master-branding");
    } else {
      status = newValue;
    }

    if (status === "Completed") {
      const updatedRowsInactives = completed.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setCompleted(updatedRowsInactives);
    } else {
      const updatedRowsOfferID = offerID.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setOfferID(updatedRowsOfferID);
    }
  };

  const mapFilterOptions = (options) => {
    const filterOptions = options.map((option) => {
      return option.label;
    });
    return filterOptions;
  };

  const columns = [
    {
      field: "id",
      headerName: " ID",
      width: 70,
      headerAlign: "center",
      align: "center",
      visible: "false",
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "offerID",
      headerName: "Offer ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "offerTitle",
      headerName: "Elemento",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "affiseLink",
      headerName: "Affise Link",
      width: 200,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Tooltip title={params.value}>
            <a
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "var(--blueFour)" }}
            >
              {params.value}
            </a>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "responsible",
      headerName: "Responsable",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleUserSelect
          {...params}
          users={users.filter((user) =>
            [
              "Ines Spitaleri",
              "Carolina Racana",
              "Katalina Palomino",
              "Sara Lopez",
              "Belén Suero",
              "Ignacio Currás",
            ].includes(user.username)
          )}
          field={"responsible"}
          handleEditResponseChange={handleEditResponseChange}
        />
      ),
    },
    {
      field: "owner",
      headerName: "Owner",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleUserSelect
          {...params}
          users={users.filter((user) =>
            [
              "Felicitas Casado",
              "Maria Camila Arango",
              "Agustina Iemmi",
              "Natalia Illera",
              "Laura De La Cruz",
            ].includes(user.username)
          )}
          field={"owner"}
          handleEditResponseChange={handleEditResponseChange}
        />
      ),
    },
    {
      field: "offerStatus",
      headerName: "Estado",
      width: 180,
      cellClassName: "no-padding",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: mapFilterOptions(offerStatusMasterBranding),

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"offerStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={offerStatusMasterBranding}
        />
      ),
    },
    {
      field: "duration",
      headerName: "Duración",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Duration {...params} selectionRow={selectionRow} />
      ),
    },
    {
      field: "conversionType",
      headerName: "Conversion Type",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"conversionType"}
          handleEditResponseChange={handleEditResponseChange}
          options={["CPC", "CPM", "CPSE", "CPV"]}
          spanText={params.row.conversionType}
          initialValue={params.row.conversionType}
          label="Conversion Type"
        />
      ),
    },
    {
      field: "cap",
      headerName: "Cap",
      width: 80,
      headerAlign: "center",
      align: "center",

      renderEditCell: (params) => (
        <Cap {...params} setCap={setCap} setTimeframe={setTimeframe} />
      ),
      editable: true,
    },
    {
      field: "timeframe",
      headerName: "Timeframe",
      width: 140,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"timeframe"}
          handleEditResponseChange={handleEditResponseChange}
          options={["Month", "Day", "All"]}
          spanText={
            params.row.timeframe === "day" || params.row.timeframe === "Day" ? (
              <p className="cell-data">Daily</p>
            ) : params.row.timeframe === "month" ||
              params.row.timeframe === "Month" ? (
              <p className="cell-data">Monthly</p>
            ) : params.row.timeframe === "all" ||
              params.row.timeframe === "All" ? (
              <p className="cell-data">Open</p>
            ) : (
              <p className="cell-data">{params.row.timeframe}</p>
            )
          }
          initialValue={params.row.timeframe}
          label="Timeframe"
        />
      ),
    },
    {
      field: "trafficSources",
      headerName: "Traffic Sources Allowed",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"trafficSources"}
          handleEditResponseChange={handleEditResponseChange}
          options={formatos.map((opt) => opt.name)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.trafficSources}
          label="Traffic Sources Allowed"
        />
      ),
    },
    {
      field: "platform",
      headerName: "Plataforma",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"platform"}
          handleEditResponseChange={handleEditResponseChange}
          options={plataformas.map((opt) => opt)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.platform}
          label="Plataforma"
        />
      ),
    },
    {
      field: "advertiserName",
      headerName: "Advertiser",
      width: 160,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: OptionsFromColumns(offerID, "advertiserName"),
      cellClassName: "cell-content",
      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip title={params.value}>
            <p className="cell-data">{params.value}</p>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "typeOfContract",
      headerName: "Tipo de contratación",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"typeOfContract"}
          handleEditResponseChange={handleEditResponseChange}
          options={tiposContratacion.map((opt) => opt)}
          spanText={params.row.typeOfContract}
          initialValue={params.row.typeOfContract}
          label="Tipo de contratación"
        />
      ),
    },
    {
      field: "targeting",
      headerName: "Targeting",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"targeting"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.targeting}
        />
      ),
    },
    {
      field: "test",
      headerName: "¿Test?",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"test"}
          handleEditResponseChange={handleEditResponseChange}
          options={testOptions.map((opt) => opt.name)}
          spanText={params.row.test === true ? "Sí" : "No"}
          initialValue={params.row.test === true ? "Sí" : "No"}
          label="¿Test?"
        />
      ),
    },
    {
      field: "billingMonth",
      headerName: "Mes de Facuración",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"billingMonth"}
          handleEditResponseChange={handleEditResponseChange}
          options={meses.map((opt) => opt)}
          spanText={params.row.billingMonth}
          initialValue={params.row.billingMonth}
          label="Mes de Facturación"
        />
      ),
    },

    {
      field: "cumplimiento",
      headerName: "Cumplimiento",
      width: 120,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        if (
          params.row.timeframe !== "month" ||
          params.row.pendingQty === 0 ||
          params.row.pendingQty === null ||
          params.row.cap === 0
        ) {
          return null;
        }
        let cumplimiento = (params.row.pendingQty / params.row.cap) * 100;
        return `${cumplimiento.toFixed(2)}%`;
      },
    },

    {
      field: "revenueUSD",
      headerName: "Revenue USD",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "revenueMAnunciante",
      headerName: "Revenue Moneda Anunciante",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "monedaAnunciante",
      headerName: "Moneda Anunciante",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"monedaAnunciante"}
          handleEditResponseChange={handleEditResponseChange}
          options={Currencies}
          spanText={<p className="cell-data">{params.value}</p>}
          initialValue={params.row.monedaAnunciante}
          label="Moneda Anunciante"
          offerID={params.row.offerID}
          backendUrl={backendUrl}
          clave={key}
        />
      ),
    },
    {
      field: "payoutUSD",
      headerName: "Payout USD",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "totalRevenueUSD",
      headerName: "Revenue Total USD",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "totalPayoutUSD",
      headerName: "Payout Total USD",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "totalProfitUSD",
      headerName: "Profit Total USD",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
  ];

  const controlEdit = async (params) => {
    let ids = selectionRow.length !== 0 ? selectionRow : [params.id];
    switch (params.field) {
      case "payoutAceptado":
        await editBoardField(
          "/master-branding",
          ids,
          params.field,
          payoutAceptado
        );
        setRefreshState(true);
        break;
      case "estimadoCapMensual":
        await editBoardField("/master-branding", ids, params.field, capMensual);
        setRefreshState(true);
        break;
      case "cap":
        if (timeframe && cap) {
          await postCapAffise(cap, timeframe, [], params.row.offerID);
          setRefreshState(true);
        }
        break;
      case "revenueMAnunciante":
        const dataOffer = {
          revenueMonedaAnunciante: revenueMonedaAnunciante,
          id: params.row.offerID,
        };
        await editOfferAffise(dataOffer);
        setRefreshState(true);
        break;
      default:
        break;
    }
  };

  const getGroupRows = (t) => {
    return t === "Offer ID" ? offerID : t === "Completed" ? completed : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  const duplicateRows = async () => {
    setPosting(true);
    const rows = offerID.filter((item) => selectionRow.includes(item.id));
    await duplicateRowsMasterBranding(rows);
    setRefreshState(true);
    setPosting(false);
  };

  return (
    <div
      className={container.globalContainer}
      style={{ padding: isMobile ? "5px 10px" : "10px 30px" }}
    >
      <ThemeProvider theme={theme}>
        <Button
          variant="outlined"
          onClick={duplicateRows}
          disabled={posting ? posting : selectionRow.length > 0 ? false : true}
        >
          Duplicar row"
        </Button>
      </ThemeProvider>

      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        position={position}
        setPosition={setPosition}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        controlEdit={controlEdit}
        refreshState={refreshState}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        viewFilter={viewFilter}
        setViewFilter={setViewFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        pathname={pathname}
        updateBoard={updateBoard}
        updateRows={updateRows}
        selectionRow={selectionRow}
        setSelectionRow={setSelectionRow}
        selectedView={selectedView}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default AlunaMasterBranding;
