import { React } from "react";
import Header from "../../../../components/global/Header.jsx";
import container from "../../../../styles/Global.module.css";
import { useState } from "react";
import { hopAlertsType } from "../../../../json/BoardStyles.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const HopAlerts = () => {
  const [groups] = useState(["Supply", "Demand"]);
  const [status, setStatus] = useState("Alerts");
  const apiUrl = `${backendUrl}/api/hop/alertsKala`;

  const [supply, setSupply] = useState([]);
  const [demand, setDemand] = useState([]);

  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "threads"],
    right: [],
  });

  const generateUniqueIds = (data) => {
    return data.map((item, index) => {
      return { ...item, id: `alert_${index}` };
    });
  };

  const filterBySide = (rows, status) => {
    const filters = {
      Supply: (item) => !item.type.includes("demand"),
      Demand: (item) => item.type.includes("demand"),
    };

    return rows.filter(filters[status]);
  };

  const filterItemsAndSetGroups = (items) => {
    const alertsWithIds = generateUniqueIds(items);

    setSupply(filterBySide(alertsWithIds, "Supply"));
    setDemand(filterBySide(alertsWithIds, "Demand"));
  };

  const getGroupRows = (groupName) => {
    return groupName === "Supply"
      ? supply
      : groupName === "Demand"
      ? demand
      : [];
  };

  const formatNumber = (value) => {
    if (value === "" || isNaN(value)) {
      return "";
    }
    return new Intl.NumberFormat("es-ES", {
      maximumFractionDigits: 2,
    }).format(value);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "tagID",
      headerName: "Tag ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "platform",
      headerName: "Platform",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "Type",
      width: 240,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        const criticityStyle = hopAlertsType[params.row.type] || {};

        return (
          <div
            style={{
              width: "100%",
            }}
          >
            {params.row.type && (
              <p
                style={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...criticityStyle,
                }}
              >
                {params.row.type}
              </p>
            )}
          </div>
        );
      },
    },
    {
      field: "impressions",
      headerName: "Impressions",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "supplyRequests",
      headerName: "Supply Requests",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "demandResponses",
      headerName: "Demand Responses",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "winRate",
      headerName: "Win Rate",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: "cost",
      headerName: "Cost",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params.value),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        checkboxSelection={false}
      />
    </div>
  );
};

export default HopAlerts;
