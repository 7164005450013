import * as React from "react";
import axios from "axios";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Autocomplete,
  TextField,
  Typography,
  Popper,
  Fade,
  Paper,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from "../../../UserContext";
import container from "../../../styles/Global.module.css";
import fotoPerfil from "../../../assets/perfilionvo.png";
import CustomToolBar from "../../../components/global/CustomToolBar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DataTable = () => {
  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const { key, setKey } = useContext(UserContext);

  useEffect(() => {
    setKey(localStorage?.getItem("jwt"));

    const fetchUsers = async () => {
      if (key) {
        try {
          const response = await fetch(
            `${backendUrl}/api/users?populate=role&populate=avatar`,
            {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            }
          );

          const data = await response.json();

          const users = data.map((user) => ({
            id: user?.id,
            username: user?.username,
            role: user?.role?.id,
            email: user?.email,
            avatar: user?.avatar?.url,
            team: getTeamName(user?.email),
          }));

          setRows(users);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      }
    };

    const fetchRoles = async () => {
      if (key) {
        try {
          const response = await fetch(
            `${backendUrl}/api/users-permissions/roles`,
            {
              headers: {
                Authorization: `Bearer ${key}`,
              },
            }
          );

          const data = await response.json();
          setRoles(data.roles);
        } catch (error) {
          console.error("Error fetching roles:", error);
        }
      }
    };

    fetchUsers();
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const handleRoleChange = async (id, newRole) => {
    const newRoleId = newRole?.id || "";
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, role: newRoleId } : row))
    );

    try {
      await axios.put(
        `${backendUrl}/api/users/${id}`,
        { role: newRoleId },
        {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
      );

      await axios.put(
        `${backendUrl}/api/users/${id}`,
        { invalidateToken: "true" },
        {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        }
      );
      console.log("Role updated successfully");
    } catch (error) {
      console.error("Error updating role:", error);
    }
  };

  const getTeamName = (email) => {
    const domain = email.split("@")[1];
    switch (domain) {
      case "houseofpubs.com":
        return "HOUSE OF PUBS";
      case "aluna.co":
        return "ALUNA";
      case "ionvogroup.com":
        return "IONVO";
      case "compao.com":
        return "COMPAO";
      case "roundler.com.br":
        return "ROUNDLER";
      default:
        return "other";
    }
  };

  const RoleCell = ({ value, rowId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const popperRef = useRef(null);
    const [arrowRef, setArrowRef] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);
    };

    const handleClickOutside = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        anchorEl &&
        !anchorEl.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    useEffect(() => {
      if (open) {
        document.addEventListener("click", handleClickOutside);
      } else {
        document.removeEventListener("click", handleClickOutside);
      }

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? "transition-popper" : undefined;

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          onClick={handleClick}
          style={{
            cursor: "pointer",
            color: "gray",
          }}
        >
          {roles.find((role) => role.id === value)?.name || "No Role"}
        </Typography>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          ref={popperRef}
          placement="bottom"
          style={{ zIndex: 1 }}
          modifiers={[
            {
              name: "flip",
              enabled: false,
            },
            {
              name: "preventOverflow",
              options: {
                altBoundary: true,
                tether: false,
              },
            },
            {
              name: "offset",
              options: {
                offset: [0, 15],
              },
            },
            {
              name: "arrow",
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ padding: "20px", position: "relative" }}>
                <span
                  ref={setArrowRef}
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    background: "white",
                    transform: "rotate(45deg)",
                    top: "-5px",
                    left: "calc(50% - 5px)",
                  }}
                />
                <Autocomplete
                  options={roles}
                  getOptionLabel={(option) => `${option.name}`}
                  value={roles.find((role) => role.id === value) || null}
                  onChange={(event, newValue) => {
                    handleRoleChange(rowId, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Role" variant="outlined" />
                  )}
                  sx={{ width: "210px" }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div>
                        <strong>{option.name}</strong>
                        <br />
                        <span style={{ fontSize: "small", color: "gray" }}>
                          {option.description}
                        </span>
                      </div>
                    </li>
                  )}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  };

  const columns = [
    { field: "id", headerName: "User ID", width: 100 },
    {
      field: "username",
      headerName: "Name",
      width: 350,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "36px",
                height: "36px",
                marginRight: "10px",
              }}
            >
              <img
                src={
                  params?.row?.avatar
                    ? `${backendUrl}${params?.row?.avatar}`
                    : fotoPerfil
                }
                style={{ width: "36px", height: "36px", borderRadius: "40px" }}
                alt="Perfil"
              />
            </div>
            <div>
              <div style={{ fontSize: "medium" }}>{params.row.username}</div>
              <div style={{ fontSize: "small", color: "gray" }}>
                {params.row.email}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      width: 250,
      renderCell: (params) => (
        <RoleCell value={params.value} rowId={params.row.id} />
      ),
    },
    {
      field: "team",
      headerName: "Team",
      width: 250,
      renderCell: (params) => (
        <div
          style={{
            fontSize: "small",
            backgroundColor: "var(--blueLow)",
            borderRadius: "9999px",
            padding: "8px",
            color: "var(--blueFour)",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={container.globalContainer}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontWeight: "600" }}>Users</h1>
        <Tooltip title="Usuarios registrados">
          <HelpOutlineIcon
            sx={{
              margin: "0 5px",
              color: "gray",
              fontSize: "18px",
              cursor: "pointer",
            }}
          />
        </Tooltip>
        <span style={{ fontSize: "16px", color: "var(--red)" }}>
          {rows?.length}
        </span>{" "}
      </div>

      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{ marginBottom: "10px" }}
        >
          <Tab label="Users & Roles" />
          <Tab label="Roles" />
        </Tabs>
        {tabValue === 0 && (
          <Box sx={{ height: 700, width: "100%" }}>
            <DataGridPro
              rows={rows}
              columns={columns}
              sx={{ backgroundColor: "white" }}
              slots={{
                toolbar: () => (
                  <CustomToolBar
                  // apiRef={apiRef}
                  // pathname={pathname}
                  // updateBoard={updateBoard}
                  // selectedView={selectedView}
                  />
                ),
              }}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
            />
          </Box>
        )}
        {tabValue === 1 && (
          <Box sx={{ height: 600, width: "100%" }}>{/*  Roles */}</Box>
        )}
      </Box>
    </div>
  );
};

export default DataTable;
