import React from "react";
import { Link } from "react-router-dom";
import { Typography, Paper } from "@mui/material";
import container from "../../styles/Global.module.css";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

const AdminPanel = () => {
  return (
    <div className={container.globalContainer}>
      {/* <Typography variant="h4" gutterBottom>
        Admin Panel
      </Typography> */}
      <Link to="/admin/users-and-roles" style={{ textDecoration: "none" }}>
        <Paper
          elevation={1}
          sx={{
            padding: "20px",
            marginBottom: "20px",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "200px",
            height: "200px",
          }}
        >
          <InsertEmoticonIcon
            sx={{ marginBottom: "10px", fontSize: "60px", color: "var(--red)" }}
          />
          <Typography variant="h4" color="var(--red)">
            Users & Roles
          </Typography>
        </Paper>
      </Link>
    </div>
  );
};

export default AdminPanel;
