export const steps = [
  "Campos Obligatorios",
  "General",
  "Tracking & Targeting Groups",
  "Payouts",
  "Caps",
  "Landing Pages",
  "Logo",
  "Creatives",
  "Kala",
  // "Data a postear",
];

export const editTabs = [
  "Campos Obligatorios",
  "General",
  "Tracking & Targeting Groups",
  "Payouts",
  "Landing Pages",
  "Logo",
  "Creatives",
  // "Data a postear",
];

export const divStyle = {
  display: "flex",
  justifyContent: "center",
  marginBottom: "30px",
  width: "100%",
};

export const paymentType = [
  { value: "fixed", name: "CPA (fixed)" },
  { value: "percent", name: "CPS (percent)" },
  { value: "mixed", name: "RPS/CPA (mixed)" },
];

export const status = [
  { value: "stopped", name: "Disabled" },
  { value: "active", name: "Active" },
  { value: "suspended", name: "Paused" },
];

export const capTimeframe = [
  { value: "day", name: "Day" },
  { value: "month", name: "Month" },
  { value: "all", name: "All" },
];

export const capType = [
  { value: "conversions", name: "Conversions" },
  { value: "payouts", name: "Payouts" },
  { value: "clicks", name: "Clicks" },
];

export const typeOfClient = [
  { value: "A", name: "A" },
  { value: "B", name: "B" },
  { value: "C", name: "C" },
  { value: "B+", name: "B+" },
];

export const encargadaCsm = [
  { value: "Maria Camila Arango", name: "Maria Camila Arango" },
  { value: "Felicitas Casado", name: "Felicitas Casado" },
  { value: "Agustina Iemmi", name: "Agustina Iemmi" },
  {
    value: "Laura De La Cruz",
    name: "Laura De La Cruz",
  },
  { value: "Natalia Illera Quintero", name: "Natalia Illera Quintero" },
];

export const programManager = [
  { value: "Carolina Racana", name: "Carolina Racana" },
  { value: "Belen Suero", name: "Belen Suero" },
  { value: "Katalina Palomino", name: "Katalina Palomino" }
];


export const pixelVerification = [
  { value: "Verificado", name: "Verificado" },
  { value: "No Verificado", name: "No Verificado" },
  { value: "Sin Pixel", name: "Sin Pixel" },
];

export const otherOptions = [
  {
    value: "Direct",
    title: "Direct",
  },
  { value: "Cliente sensible calidad", title: "Cliente sensible calidad" },
  { value: "Cliente nuevo calidad", title: "Cliente nuevo calidad" },
  { value: "Solicitar link a CSM", title: "Solicitar link a CSM" },
];

export const publisherOptions = [
  {
    value: "Affiliates",
    title: "Affiliates",
  },
  { value: "The Box", title: "The Box" },
  { value: "Compao", title: "Compao" }
];

export const conversionTypeKala = [
  { value: "CPL", name: "CPL" },
  { value: "CPA", name: "CPA" },
  { value: "CPI", name: "CPI" },
  { value: "CPC", name: "CPC" },
  { value: "CPM", name: "CPM" },
  { value: "CPS", name: "CPS" },
  { value: "CPV", name: "CPV" },
  { value: "CPSE", name: "CPSE" },
];

export const privacyLevel = [
  { value: "public", name: "Public" },
  { value: "protected", name: "Premoderation" },
  { value: "private", name: "Private" },
];

export const responsables = [
  { value: "Ines Spitaleri", username: "Ines Spitaleri" },
  { value: "Carolina Racana", username: "Carolina Racana" },
  { value: "Katalina Palomino", username: "Katalina Palomino" },
  { value: "Sara López Graciano", username: "Sara López Graciano" },
  { value: "Belén Suero", username: "Belén Suero" },
  { value: "Ignacio Currás", username: "Ignacio Currás" },
];

export const formatos = [
  { value: "Display", name: "Display" },
  { value: "Rich Media Interactivo", name: "Rich Media Interactivo" },
  { value: "Native ads", name: "Native ads" },
  { value: "Video Pre roll", name: "Video Pre roll" },
  { value: "Video Outstream", name: "Video Outstream" },
  { value: "Video Interstitial", name: "Video Interstitial" },
  { value: "Email Marketing", name: "Email Marketing" },
  { value: "Push Notifications", name: "Push Notifications" },
  { value: "Connected TV", name: "Connected TV" },
  { value: "Search", name: "Search" },
];

export const plataformas = [
  "Afiliados",
  "Google",
  "Meta",
  "Stack",
  "Propeller",
  "Taboola",
  "Sendy",
  "Bing",
];

export const tiposContratacion = ["Deal", "Directo"];

export const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const testOptions = [
  { value: "true", name: "Sí" },
  { value: "false", name: "No" },
];

export const comercial = [
  { value: "Victor Perez", username: "Victor Perez" },
  { value: "Alexis Peña", username: "Alexis Peña" },
];

export const agencia = [
  "Dentsu",
  "Directo",
  "Epa Digital",
  "Ganem",
  "Havas",
  "IPG",
  "Jelly Fish",
  "LLYC",
  "Mediacom",
  "Mindshare",
  "OMD",
  "PHD",
  "Publicis",
  "T2ó",
  "Universal Maccann",
  "Wavemaker",
  "Dynamicevents",
  "Glow",
  "8/24"
];

export const tipoDeCompra = [
  { value: "CPL", name: "CPL" },
  { value: "CPA", name: "CPA" },
  { value: "CPI", name: "CPI" },
  { value: "CPS", name: "CPS" },
  { value: "CPC", name: "CPC" },
  { value: "CPM", name: "CPM" },
  { value: "CPV", name: "CPV" },
];

export const crmTipos = ["Branding", "Performance"];

export const anio = ["2024", "2025"];

export const numeroDeMeses = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const porcentajeDeAvance = ["0", "25", "50", "75", "100"];

export const broker = ["No", "Jorge", "Alejandro", "Virginia"];
export const sortAlphabetically = (arr, property) => {
  return arr.sort((a, b) => {
    const nameA = a[property]?.toUpperCase();
    const nameB = b[property]?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
