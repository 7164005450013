import * as React from "react";
import {
  DataGridPro,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  Autocomplete,
  Checkbox,
  ThemeProvider,
  createTheme,
  Typography,
  Popper,
  ClickAwayListener,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { fetchData, getStatus } from "../../../../utils/ApiCall";
import { useEffect } from "react";
import CustomToolBar from "../../../../components/global/CustomToolBar";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Button from "@mui/material/Button";
import { COLORS } from "../../../../json/StatusOptions";

export const isNavigationKey = (key) =>
  key === "Home" ||
  key === "End" ||
  key.indexOf("Arrow") === 0 ||
  key.indexOf("Page") === 0 ||
  key === " ";

function CustomGridTreeDataGroupingCell(props) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== "group") {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <span
            onClick={handleClick}
            tabIndex={-1}
            style={{ cursor: "pointer" }}
          >
            TOTAL
          </span>
        ) : rowNode?.depth === 0 ? (
          <span></span>
        ) : (
          <span />
        )}
      </div>
    </Box>
  );
}

const groupingColDef = {
  headerName: "Total",
  width: 100,
  renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
};

const getRowClassName = (params) => {
  const row = params.row;

  if (row.offerTitle === "TOTAL") {
    return "total-row";
  }
  if (row?.hierarchy?.length === 1) {
    return "highlighted-row";
  }

  return "";
};

const generateUniqueId = () => {
  const min = 10;
  const max = 1000;
  return Math.floor(Math.random() * (max - min) + min);
};

const getTreeDataPath = (row) => {
  return row.hierarchy;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const names = [
  { value: "PAUSED" },
  { value: "PAUSED - CAP REACHED" },
  { value: "ACTIVE" },
  { value: "RUNNING" },
  { value: "INACTIVE" },
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function TreeDataCustomGroupingColumn() {
  const apiService = `${backendUrl}/api/offer-service`;
  const [offers, setOffers] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingRows, setIsLoadingRows] = useState(false);
  const [searchByOfferID, setSearchByOfferID] = useState(null);
  const [searchByStatus, setSearchByStatus] = useState(false);
  const [newArray, setNewArray] = useState([]);
  const [filterOn, setFilterOn] = useState(false);
  const [offerStatus, setOfferStatus] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);
  const [enebleButton, setEnableButton] = useState(false);
  const [offerID, setOfferID] = useState("");

  const apiRef = useGridApiRef();

  //Funcion para buscar offers en el autocomplete
  const searchOffers = async (query, searchParam) => {
    const url = `${apiService}/autocompleteOffersMasters?${searchParam}=${query}`;
    const resOffers = await fetchData(url);
    setSearchResult(resOffers);
  };

  useEffect(() => {
    if (searchText && !offerID) {
      if (searchByOfferID) {
        searchOffers(searchText, "offerID");
      } else {
        searchOffers(searchText, "offerTitle");
      }
      setIsSearching(true);
    } else if ((offerStatus || !isTextFieldClicked) && !offerID) {
      // aca
      searchOffers(offerStatus?.value, "offerStatus");
      setIsSearching(true);
      setIsListOpen(true);
    } else {
      setSearchResult([]);
      setIsSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    searchByOfferID,
    searchByStatus,
    offerStatus,
    isTextFieldClicked,
  ]);
  //Funcion para seguir el value en el autocomplete
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setSearchByOfferID(!isNaN(e.target.value));
    setOfferStatus("");
    setIsTextFieldClicked(false);
  };

  //Funcion para cargar la offer seleccionada
  const handleLiClick = async (value) => {
    if (value) {
      setOfferID(value.offerID);
      setSearchText(value.offerTitle);
      setIsTextFieldClicked(false);
      setIsListOpen(false);
      setEnableButton(true);
    }
  };

  const handleSubmit = async () => {
    if (!isNaN(offerID)) {
      setEnableButton(false);
      setOffers([]);
      setIsLoadingRows(true);

      const seguimientoUrl = `${apiService}/campaignTracking?offerID=${offerID}`;

      const seguimientoData = await fetchData(seguimientoUrl);

      const modifiedOffers = seguimientoData.map((offer) => {
        if (offer.offerID) {
          return {
            ...offer,
            clicks: offer.totalClicks,
            pending: offer.totalPending,
            declined: offer.totalDecline,
            cr: offer.totalCr,
            revenue: offer.totalRevenue,
            payouts: offer.totalPayout,
            profit: offer.totalProfit,
            cumplimientoCampana: `${
              (offer.totalPending * 100) / offer.totalCap
            }%`,
          };
        } else {
          return offer;
        }
      });

      setIsLoadingRows(false);
      setOffers(modifiedOffers);
      setSearchResult([]);
      setSearchText("");
      setOfferStatus("");
      setIsSearching(true);
      setIsListOpen(false);

      const affiliatesNames = modifiedOffers
        .map((off) => off?.hierarchy[1] || "")
        .filter((name) => name !== "");

      const affiliates = affiliatesNames.filter(
        (value, index, originalArray) => {
          return originalArray.indexOf(value) === index;
        }
      );
      setAffiliates(affiliates);

      setIsLoadingRows(false);
      setOffers(seguimientoData);
      setAffiliates([]);
      setOfferID("");
    }
  };

  //Funcion para saber que se esta filtrando
  const handleFilterModel = (filterModel) => {
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      setFilterOn(true);
    } else {
      setFilterOn(false);
    }
  };

  //Funcion para redondear un decimal
  const roundDecimal = (numero) => {
    const numeroRedondeado = Math.round(numero * 10) / 10;
    return numeroRedondeado;
  };

  const formatCumplimiento = (cumplimiento) => {
    if (isNaN(cumplimiento) || !isFinite(cumplimiento)) {
      return "0%";
    }
    return `${roundDecimal(cumplimiento)}%`;
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    let status;
    if (field !== "offerStatus") {
      status = await getStatus(rowID, "seguimiento-campana2");
    } else {
      status = newValue;
    }
    // else {
    //       const updatedRowsOfferID = offerID.map((row) => {
    //         if (+row.id === +rowID) {
    //           return { ...row, [`${field}`]: newValue };
    //         }
    //         return row;
    //       });
    //       setOfferID(updatedRowsOfferID);
    //     }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAlerts, setSelectedAlerts] = useState([]);

  const handlePopoverOpen = (event, alerts) => {
    setAnchorEl(event.currentTarget);
    setSelectedAlerts(alerts);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedAlerts([]);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const [open, setOpen] = React.useState(false);

  const handleClick = (event, alerts) => {
    setAnchorEl(event.currentTarget);
    setSelectedAlerts(alerts);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  const [arrowRef, setArrowRef] = React.useState(null);

  const [arrow, setArrow] = React.useState(false);

  const columns = [
    { field: "offerID", headerName: "Offer ID", width: 80 },
    { field: "offerTitle", headerName: "Offer", width: 120 },
    { field: "advertiser", headerName: "Advertiser", width: 120 },
    {
      field: "timeframe",
      headerName: "Timeframe",
      width: 80,
    },
    {
      field: "conversionType",
      headerName: "Conversion Type",
      width: 120,
    },
    {
      field: "vertical",
      headerName: "Vertical",
      width: 80,
    },
    {
      field: "country",
      headerName: "Geo",
      width: 60,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 150,
    },
    {
      field: "targeting",
      headerName: "Targeting",
      width: 150,
    },
    {
      field: "programManager",
      headerName: "Program Manager",
      width: 120,
    },
    { field: "status", headerName: "Estado", width: 100 },
    {
      field: "affiliate",
      headerName: "Afiliado",
      width: 120,

      valueGetter: (params) => {
        const hierarchy = params.row.hierarchy;
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return "";
        }

        return hierarchy[hierarchy.length - 1];
      },
    },
    {
      field: "affiliateAlert",
      headerName: "Alerts",
      width: 120,
      renderCell: (params) => {
        const alerts = params.row.alerts || [];

        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return "";
        }

        return (
          <div style={{ width: "100%", height: "100%" }}>
            {alerts.length !== 0 && (
              <button
                aria-describedby={id}
                type="button"
                onClick={(event) => handleClick(event, alerts)}
                style={{
                  backgroundColor:
                    alerts.length < 5
                      ? "rgb(0, 200, 117)"
                      : alerts.length > 5 && alerts.length < 10
                      ? "var(--orange)"
                      : "var(--red)",
                  color: "white",
                  border: "none",
                  outline: "none",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              >
                {alerts.length} {alerts.length === 1 ? "Alerta" : "Alertas"}
              </button>
            )}

            <ClickAwayListener onClickAway={handleClickAway}>
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom"
                modifiers={[
                  {
                    name: "flip",
                    enabled: true,
                    options: {
                      altBoundary: true,
                      rootBoundary: "document",
                      padding: 8,
                    },
                  },
                  {
                    name: "preventOverflow",
                    enabled: true,
                    options: {
                      altAxis: false,
                      tether: true,
                      rootBoundary: "document",
                      padding: 8,
                    },
                  },
                ]}
              >
                <Box
                  sx={{
                    p: 1,
                    bgcolor: "background.paper",
                    boxShadow: "rgba(0, 0, 0, 0.03) 0px 4px 12px",
                  }}
                >
                  <Grid container spacing={1} sx={{ width: "500px" }}>
                    {selectedAlerts.map((alert, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <ListItem
                          sx={{
                            color: "white",
                            backgroundColor: COLORS[alert.type] || "white",
                            padding: "4px",
                            borderRadius: "2px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography fontSize="medium">
                            {alert.type}
                          </Typography>
                          <Typography fontSize="small">
                            Cantidad: {alert.quantity}
                          </Typography>
                        </ListItem>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Popper>
            </ClickAwayListener>
          </div>
        );
      },
    },
    {
      field: "clicks",
      headerName: "Clicks",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalClicks;
        }
        if (params.row.hierarchy.length === 2) {
          return params.row.clicks;
        }
      },
    },
    {
      field: "trafficback",
      headerName: "Trafficback",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalTrafficback;
        }
        if (params.row.hierarchy.length === 2) {
          return params.row.trafficback;
        }
      },
    },
    {
      field: "pending",
      headerName: "Pending",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalPending;
        }

        if (params.row.hierarchy.length === 2) {
          return params.row.pending;
        }
      },
    },
    {
      field: "declined",
      headerName: "Declined",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalDecline;
        }
        if (params.row.hierarchy.length === 2) {
          return params.row.declined;
        }
      },
    },
    {
      field: "cr",
      headerName: "CR",
      width: 80,
      renderCell: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalCr;
        }

        if (params.row.hierarchy.length === 2) {
          return params.row.cr;
        }
      },
    },
    {
      field: "affiliateCap",
      headerName: "Cap asignado por Afiliado",
      width: 120,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          const allRows = Array.from(params.api.getRowModels().values());

          const totalCap = allRows.reduce((acc, row) => {
            if (row.hierarchy.length === 2) {
              return acc + (parseFloat(row.cap.value) || 0);
            }
            return acc;
          }, 0);

          return totalCap;
        }

        if (params.row.hierarchy.length === 2) {
          return params.row.cap.value;
        }
      },
    },
    {
      field: "totalCap",
      headerName: "Cap Total Campaña",
      width: 120,
    },
    {
      field: "compliance",
      headerName: "Porcentaje de Cumplimiento",
      width: 120,

      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalCompliance ? `${params.row.totalCompliance}%` : null
        }

        if (params.row.hierarchy.length === 2) {
          return params.row.compliance ? `${params.row.compliance}%` : null
        }
      },
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 80,
      valueGetter: (params) => {
        const revenue = `$${roundDecimal(params?.row?.revenue)}`;
        const totalRevenue = `$${roundDecimal(params?.row?.totalRevenue)}`;

        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return totalRevenue;
        }

        if (params.row.hierarchy.length === 2) {
          return revenue;
        }
      },
    },
    {
      field: "payouts",
      headerName: "Payout",
      width: 80,
      valueGetter: (params) => {
        const payout = `$${roundDecimal(params?.row?.payouts)}`;
        const totalPayout = `$${roundDecimal(params?.row?.totalPayout)}`;

        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return totalPayout;
        }

        if (params.row.hierarchy.length === 2) {
          return payout;
        }
      },
    },
    {
      field: "profit",
      headerName: "Profit",
      width: 80,
      valueGetter: (params) => {
        const profit = `$${roundDecimal(params?.row?.profit)}`;
        const totalProfit = `$${roundDecimal(params?.row?.totalProfit)}`;

        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return totalProfit;
        }

        if (params.row.hierarchy.length === 2) {
          return profit;
        }
      },
    },
    {
      field: "payoutAffiliate",
      headerName: "Payout Affiliate",
      width: 120,
      renderCell: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return `$${params.row.totalPayoutAssigned}`
        }

        return `$${params.row.payoutAffiliate}`
      },
    },
    {
      field: "revenueAffiliate",
      headerName: "Revenue Affiliate",
      width: 120,
      renderCell: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return `$${params.row.totalRevenueAssigned}`
        }

        return `$${params.row.revenueAffiliate}`
      },
    },
  ];

  const handleChange = (event, newValue) => {
    setOfferStatus(newValue);
    setSearchByStatus(true);
    setSearchText("");
  };

  return (
    <div style={{ height: "100%", width: "100%", backgroundColor: "#f8f7ff" }}>
      <Box sx={{ padding: "15px 30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <div style={{ marginRight: "15px" }}>
                <TextField
                  type="text"
                  label="Offer ID / Offer Title"
                  value={searchText}
                  disabled={isLoadingRows}
                  onChange={handleSearchTextChange}
                  onClick={() => setIsTextFieldClicked(true)}
                  sx={{
                    width: "350px",
                    backgroundColor: "white",
                    position: "relative",
                  }}
                />

                {isSearching &&
                  searchResult.length === 0 &&
                  searchText !== "" && (
                    <List
                      component="ul"
                      className="autocomplete-ul"
                      sx={{
                        position: "absolute",
                        width: "350px",
                        zIndex: "1000",
                      }}
                    >
                      <ListItem className="autocomplete-li" disabled={true}>
                        <ListItemText primary="Offer no encontrada" />
                      </ListItem>
                    </List>
                  )}

                {isSearching && isListOpen && searchResult.length > 0 && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      width: "350px",
                      zIndex: "1000",
                      position: "absolute",
                    }}
                  >
                    {isSearching &&
                      isListOpen &&
                      searchResult
                        .filter((item) => item.offerStatus)
                        .map((result) => (
                          <ListItem
                            key={result.offerID}
                            onClick={() => handleLiClick(result)}
                            className="autocomplete-li"
                            disabled={isLoadingRows}
                            sx={{
                              backgroundColor:
                                result.offerStatus === "ACTIVE"
                                  ? "var(--greenYellow)"
                                  : result.offerStatus === "PAUSED"
                                  ? "var(--paused)"
                                  : result.offerStatus ===
                                    "PAUSED - CAP REACHED"
                                  ? "var(--greenDark)"
                                  : result.offerStatus === "RUNNING"
                                  ? "var(--violet)"
                                  : result.offerStatus === "INACTIVE"
                                  ? "rgb(226, 68, 92)"
                                  : "var(--gray)",
                              color: "white",
                              fontWeight: "700",
                              margin: "5px 0px",
                              "&:hover": {
                                backgroundColor: "var(--blackLow)",
                              },
                            }}
                          >
                            <ListItemText primary={result.offerTitle} />
                          </ListItem>
                        ))}
                  </List>
                )}
              </div>

              <Autocomplete
                id="checkboxes-tags-demo"
                options={names}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.value ?? ""}
                defaultValue=""
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      size="small"
                    />
                    {option.value ?? ""}
                  </li>
                )}
                value={offerStatus ?? null}
                onChange={handleChange}
                style={{ width: 350 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Offer Status"
                    sx={{ backgroundColor: "white" }}
                  />
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "12px",
              }}
            >
              <ThemeProvider theme={theme}>
                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  disabled={!enebleButton}
                >
                  Generar
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </div>

        <DataGridPro
          slots={{
            toolbar: () => <CustomToolBar apiRef={apiRef} />,
            loadingOverlay: () => <LinearProgress />,
          }}
          loading={isLoadingRows ? true : false}
          treeData
          rows={offers}
          columns={columns}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          rowHeight={30}
          defaultGroupingExpansionDepth={-1}
          getRowClassName={getRowClassName}
          sx={{ backgroundColor: "white" }}
          getRowId={(row) =>
            row.hierarchy.length === 1 ? row.id : offers.indexOf(row)
          }
          onFilterModelChange={handleFilterModel}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                __tree_data_group__: false,
              },
            },
          }}
        />
      </Box>
    </div>
  );
}
