import React from 'react';
import KanbanCard from './KanbanCard';
import styles from './KanbanColumn.module.css';
import { Plus } from 'lucide-react';
import RowCreator from '../../pages/global/RowCreator/RowCreator';

const KanbanColumn = ({
  column,
  tasks,
  onDragStart,
  onDragOver,
  onDrop,
  updateTask,
  deleteTask,
  openTaskDetails,
  setActiveColumn,
  setIsAddTaskModalOpen,
  pathname,updateBoard
}) => {
  const handleAddTask = () => {
    setActiveColumn(column.id);
    setIsAddTaskModalOpen(true);
  };

  return (
    <div
      className={styles.column}
      onDragOver={onDragOver}
      onDrop={(e) => onDrop(e, column.id)}
    >
      <h2 className={styles.columnTitle}>{column.title}</h2>
      <div className={styles.taskList}>
        {tasks.map((task) => (
          <KanbanCard
            key={task.id}
            task={task}
            onDragStart={onDragStart}
            updateTask={updateTask}
            deleteTask={deleteTask}
            openTaskDetails={openTaskDetails}
          />
        ))}
      </div>
      <button onClick={handleAddTask} className={styles.addTaskButton}>
      <RowCreator pathname={pathname} updateBoard={updateBoard} />
        <Plus size={20} />
        Añadir una tarjeta
      </button>
    </div>
  );
};

export default KanbanColumn;