import React, { useEffect, useState } from "react";
import container from "../../styles/Global.module.css";
import BaseBoard from "../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TestCreateBoard = () => {
  const [groups, setGroups] = useState(["Alerts"]);
  const [status, setStatus] = useState("Alerts");
  const apiUrl = `${backendUrl}`;
  const [items, setItems] = useState([]);
  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [newBoardName, setNewBoardName] = useState("");
  const [newColumns, setNewColumns] = useState([]);

  const defaultColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "offerID",
      headerName: "Offer ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "offerTitle",
      headerName: "Offer Title",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
    // Fetch data and set items, affiliatesManager, etc.
  }, []);

  const getBoardColumns = () => {
    if (status === "Alerts") {
      return defaultColumns;
    }
    // Aquí puedes agregar lógica para obtener las columnas de otros boards
    // Por ejemplo, podrías tener un objeto que mapee el estado a las columnas
    // O podrías hacer una llamada a una función que devuelva las columnas basadas en el estado actual
    return newColumns;
  };

  const handleNewBoard = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAddNewBoard = () => {
    setGroups([...groups, newBoardName]);
    setModalOpen(false);
  };

  const handleNewColumnNameChange = (index, value) => {
    const updatedColumns = [...newColumns];
    updatedColumns[index].headerName = value;
    setNewColumns(updatedColumns);
  };

  const handleNewColumnFieldChange = (index, value) => {
    const updatedColumns = [...newColumns];
    updatedColumns[index].field = value;
    setNewColumns(updatedColumns);
  };

  const handleAddNewColumn = () => {
    setNewColumns([...newColumns, { field: "", headerName: "", width: 150 }]);
  };

  const filterItemsAndSetGroups = (items) => {
    // const alertsWithIds = generateUniqueIds(items);

    setItems(items);
  };

  const getGroupRows = (groupName) => {
    const rows = groupName === "Alerts" ? items : [];

    const rowsWithIds = rows.map((row, index) => {
      return { ...row, id: `${groupName}_${index}` };
    });
    return rowsWithIds;
  };

  return (
    <div className={container.globalContainer}>
      <button onClick={handleNewBoard}>Nuevo Board</button>
      <BaseBoard
        columns={getBoardColumns()}
        groups={groups}
        getGroupRows={getGroupRows}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        checkboxSelection={false}
        setGroupItems={filterItemsAndSetGroups}
      />
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleModalClose}>
              &times;
            </span>
            <label>Nombre del nuevo board:</label>
            <input
              type="text"
              value={newBoardName}
              onChange={(e) => setNewBoardName(e.target.value)}
            />
            <label>Agregar columnas:</label>
            {newColumns.map((col, index) => (
              <div key={index}>
                <input
                  type="text"
                  value={col.headerName}
                  onChange={(e) =>
                    handleNewColumnNameChange(index, e.target.value)
                  }
                  placeholder="Nombre de la columna"
                />
                <input
                  type="text"
                  value={col.field}
                  onChange={(e) =>
                    handleNewColumnFieldChange(index, e.target.value)
                  }
                  placeholder="Field"
                />
              </div>
            ))}
            <button onClick={handleAddNewColumn}>Agregar Columna</button>
            <button onClick={handleAddNewBoard}>Agregar Nuevo Board</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestCreateBoard;
