import React, { useState, useEffect } from 'react';
import KanbanBoard from './KanbanBoard';
import AddTaskModal from './AddTaskModal';
import TaskDetailsModal from './TaskDetailsModal';
import AddColumnModal from './AddColumnModal';
import { Plus } from 'lucide-react';
import styles from './Kanban.module.css';

function Kanban({items,pathname,updateBoard}) {
  const [tasks, setTasks] = useState([]);
  const [columns, setColumns] = useState([
    { id: 'Backlog', title: 'Backlog' },
    { id: 'To do', title: 'To Do' },
    { id: 'Blocked', title: 'Blocked' },
    { id: 'Doing', title: 'Doing' },
    
  ]);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const [isAddColumnModalOpen, setIsAddColumnModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);

  useEffect(() => {

    setTasks(items)
  }, [items, columns]);

  const addTask = (newTask) => {
    const id = Math.max(...tasks.map(task => task.id), 0) + 1;
    setTasks([...tasks, { ...newTask, id }]);
  };

  const addColumn = (newColumn) => {
    const id = Math.random().toString(36).substr(2, 9);
    setColumns([...columns, { ...newColumn, id }]);
  };

  const updateTask = (updatedTask) => {
    setTasks(tasks.map(task => task.id === updatedTask.id ? updatedTask : task));
  };

  const deleteTask = (taskId) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  const openTaskDetails = (task) => {
    setSelectedTask(task);
  };

  const closeTaskDetails = () => {
    setSelectedTask(null);
  };


console.log(items, "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Kanban Board</h1>
        <div className={styles.boardContainer}>
          <KanbanBoard 
            tasks={tasks} 
            columns={columns} 
            setTasks={setTasks}
            updateTask={updateTask}
            deleteTask={deleteTask}
            openTaskDetails={openTaskDetails}
            setActiveColumn={setActiveColumn}
            setIsAddTaskModalOpen={setIsAddTaskModalOpen}
            setColumns={setColumns}
            pathname={pathname} 
            updateBoard={updateBoard}
          />
          <button
            onClick={() => setIsAddColumnModalOpen(true)}
            className={styles.addColumnButton}
          >
            <Plus size={20} />
            Añadir otra lista
          </button>
        </div>
        <AddTaskModal
          addTask={addTask}
          columns={columns}
          isOpen={isAddTaskModalOpen}
          onClose={() => setIsAddTaskModalOpen(false)}
          activeColumn={activeColumn}
        />
        {selectedTask && (
          <TaskDetailsModal
            task={selectedTask}
            columns={columns}
            updateTask={updateTask}
            deleteTask={deleteTask}
            onClose={closeTaskDetails}
          />
        )}
        <AddColumnModal
          addColumn={addColumn}
          isOpen={isAddColumnModalOpen}
          onClose={() => setIsAddColumnModalOpen(false)}
        />
      </div>
    </div>
  );
}

export default Kanban;