import * as React from "react";
import { useMemo } from "react";
import Button from "@mui/material/Button";
import { useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import { fetchData, postCapAffise } from "../../../../utils/ApiCall";
import container from "../../../../styles/Global.module.css";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import Cap from "../../../../components/aluna/Cap.jsx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import dayjs from "dayjs";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const PanelDeCaps = () => {
  const [refreshState, setRefreshState] = useState(false);
  const [status, setStatus] = useState("Panel de caps");
  const [groups] = useState(["Panel de caps"]);
  const [items, setItems] = useState([]);
  const [cap, setCap] = useState("");
  const [timeframe, setTimeframe] = useState("");

  const date = new Date().toISOString();
  const first = "01";
  const firstOfMonth = date.slice(0, 8).concat(first);
  const today = date.slice(0, 10);
  const [dateFrom, setDateFrom] = useState(firstOfMonth);
  const [dateTo, setDateTo] = useState(today);

  const [isSearching, setIsSearching] = useState(false);

  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const apiUrl = `${backendUrl}/api/offer-service/getDataPanelCaps`;
  const [edit] = useState(
    localStorage?.groups?.includes("afiliacion") ? true : false
  );

  const filterItemsAndSetGroups = (items) => {
    setItems(items);
  };

  const getGroupRows = (groupName) => {
    return groupName === "Panel de caps" ? items : [];
  };


  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 70,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "link",
        headerName: "Link aff.",
        width: 100,
        renderCell: (rowData) => (
          <Button href={rowData.value} target="_blank">
            <LinkIcon style={{ color: "black" }} />
          </Button>
        ),
      },
      {
        field: "offerID",
        headerName: "Offer ID",
        width: 100,
      },
      {
        field: "offerTitle",
        headerName: "Offer",
        width: 180,
      },
      {
        field: "affiliateTitle",
        headerName: "Titulo Afiliado",
        width: 120,
      },
      {
        field: "idAffiliate",
        headerName: "ID Afiliado",
        width: 100,
      },
      {
        field: "affiliateManager",
        headerName: "Manager de Afiliado",
        width: 160,
        type: "singleSelect",
        valueOptions: ['Katalina Palomino', 'Sara López Graciano', 'Inés Spitaleri', 'Carolina Racana', 'Belén Suero'],
      },
      {
        field: "clicks",
        headerName: "Clicks",
        width: 120,
      },
      {
        field: "pendingQTY",
        headerName: "Pen Qty",
        width: 120,
      },
      {
        field: "declineQTY",
        headerName: "Dec Qty",
        width: 120,
      },
      {
        field: "cr",
        headerName: "CR",
        width: 120,
      },
      {
        field: "cap_total_offer",
        headerName: "Cap total offer",
        width: 160,
      },
      {
        field: "cap_total_offer_timeframe",
        headerName: "Cap total offer timeframe",
        width: 160,
      },
      {
        field: "cap",
        headerName: "Cap Aff",
        width: 80,
        headerAlign: "center",
        align: "center",

        renderEditCell: (params) => (
          <Cap {...params} setCap={setCap} setTimeframe={setTimeframe} />
        ),
        editable: edit,
      },
      {
        field: "timeframe",
        headerName: "Cap timeframe",
        width: 120,
        editable: edit,
        editMode: "row",
        type: "singleSelect",
        valueOptions: [
          { value: "day", label: "day" },
          { value: "month", label: "month" },
          { value: "all", label: "all" },
        ],
      },
      {
        field: "total_conversion",
        headerName: "Total Conversion",
        width: 160,
        type: "number",
      },
      {
        field: "cap_total_assigned",
        headerName: "Total cap asignado",
        width: 160,
        type: "number",
      },

      {
        field: "cap_remaining",
        headerName: "Remaining Cap",
        width: 120,
        editMode: "row",
        type: "number",
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const controlEdit = async (params) => {

    let oldCap = params.row.cap ?? 0
    let differenceCaps = parseInt(cap) - oldCap

    let cap_total_assigned = params.row.cap_total_assigned + differenceCaps
    let cap_remaining = params.row.cap_remaining - differenceCaps

    setItems((prevItems) => {
      return prevItems.map((row) => (
        row.offerID === params.row.offerID && row.idAffiliate === params.row.idAffiliate ? { ...row, cap, timeframe, cap_total_assigned, cap_remaining } :
          row.offerID === params.row.offerID ? { ...row, cap_total_assigned, cap_remaining } : row
      ))
    });

    await postCapAffise(
      cap,
      timeframe,
      [params.row.idAffiliate],
      params.row.offerID
    );


    setRefreshState(true);
  };

  const clearSearchResults = () => {
    setItems([]);
    setIsSearching(false);
  };

  const handleSearchByDate = async () => {
    try {
      clearSearchResults();
      setIsSearching(true);
      const formattedDateFrom = dateFrom
        ? dayjs(dateFrom).format("YYYY-MM-DD")
        : "";
      const formattedDateTo = dateTo ? dayjs(dateTo).format("YYYY-MM-DD") : "";

      const searchUrl = `${apiUrl}?dateFrom=${formattedDateFrom}&dateTo=${formattedDateTo}`;

      const data = await fetchData(searchUrl);

      setItems(data);
      setIsSearching(false);
    } catch (error) {
      console.error("Error al buscar datos:", error);
      setIsSearching(false);
    }
  };

  return (
    <div className={container.globalContainer}>
      <Box
        sx={{
          maxWidth: "600px",
          margin: "10px 0px 20px 0px",
        }}
      >
        <p>Date Range</p>
        <Box sx={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              localeText={{
                start: `${dateFrom}`,
                end: `${dateTo}`,
              }}
              sx={{ backgroundColor: "white", marginRight: "10px" }}
              onChange={(newValue) => {
                if (newValue[0]) setDateFrom(newValue[0]);
                if (newValue[1]) setDateTo(newValue[1]);
              }}
              disableFuture
            />
          </LocalizationProvider>
          <ThemeProvider theme={theme}>
            <Button
              variant="outlined"
              onClick={handleSearchByDate}
              disabled={isSearching}
            >
              Generar
            </Button>
          </ThemeProvider>
        </Box>
      </Box>

      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        status={status}
        setStatus={setStatus}
        refreshState={refreshState}
        controlEdit={controlEdit}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        checkboxSelection={false}
        isLoadingRows={isSearching}
      />
    </div>
  );
};

export default PanelDeCaps;
