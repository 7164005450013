export const boardStyles = {
  "& .MuiDataGrid-root": {
    border: "1px solid #dee2ff",
    zIndex: "1",
  },

  "& .centered-header": {
    textAlign: "center",
  },
  "& .MuiDataGrid-cell": {
    justifyContent: "center",
    overflow: "hidden",
    wordWrap: "break-word",
    whiteSpace: "no-wrap",
  },
  "& .no-padding": {
    padding: 0,
  },
  "& .MuiDataGrid-cell:not(:last-child)": {
    borderBottom: "1px solid #dee2ff",
    borderRight: "1px solid #dee2ff",
  },
  "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
    borderBottom: "1px solid #dee2ff",
  },
  "& .name-column--cell": {
    color: "green",
  },
  "& .status-column--cell": {
    padding: 0,
    color: "black",
  },
  "& .MuiDataGrid-row": {
    border: "none",
  },
  "& .Mui-selected": {
    border: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#fefcfd",
    color: "black",
    borderBottom: "1px solid #dee2ff",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "500",
    fontSize: "16px",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },

  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #dee2ff",
    backgroundColor: "#240046",
    color: "white",
  },
  "& .MuiDataGrid-columnHeader": {
    borderLeft: "1px solid #dee2ff",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .css-i4bv87-MuiSvgIcon-root": {
    color: "black",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#F6F8FF",
    border: "none",
  },
  "& .MuiTablePagination-selectLabel": {
    color: "white",
  },
  // "& .MuiInputBase-colorPrimary": {
  //   color: "white",
  // },
  "& .MuiTablePagination-displayedRows": {
    color: "white",
  },
  "& .MuiDataGrid-cell--editable": {
    cursor: "pointer",
  },
  // "& .css-i4bv87-MuiSvgIcon-root": {
  //   color: "var(--grayTwo)",
  // },
  "& .icons-access": {
    color: "black",
  },
  "& .css-17jaavt": {
    margin: "0",
  },
  "& .offer-status.pixelIntegration": {
    backgroundColor: "var(--pink)",
    color: "white",
  },
  "& .offer-status.comercialWOI": {
    backgroundColor: "var(--violetDark)",
    color: "white",
  },
  "& .offer-status.woi": {
    backgroundColor: "var(--orange)",
    color: "white",
  },
  "& .offer-status.affiseSetup": {
    backgroundColor: "var(--skyBlue)",
    color: "white",
  },
  "& .offer-status.active": {
    backgroundColor: "var(--greenYellow)",
    color: "white",
  },
  "& .offer-status.running": {
    backgroundColor: "var(--violet)",
    color: "white",
  },
  "& .offer-status.aon": {
    backgroundColor: "var(--blue)",
    color: "white",
  },
  "& .offer-status.paused": {
    backgroundColor: "var(--paused)",
    color: "white",
  },
  "& .offer-status.pausedCapReached": {
    backgroundColor: "var(--greenDark)",
    color: "white",
  },
  "& .offer-status.notProfitable": {
    backgroundColor: "var(--blackLow)",
    color: "white",
  },
  "& .offer-status.inactive": {
    backgroundColor: "var(--orangeElectric)",
    color: "white",
  },
  "& .offer-status.theBox": {
    backgroundColor: "var(--greenGrass)",
    color: "white",
  },
  "& .csm-status.pixelRequired": {
    backgroundColor: "var(--pinkGlow)",
    color: "white",
  },
  "& .csm-status.pixelIntegration": {
    backgroundColor: "var(--blueOcean)",
    color: "white",
  },
  "& .csm-status.pendingInfoCliente": {
    backgroundColor: "var(--uva)",
    color: "white",
  },
  "& .csm-status.batPreview": {
    backgroundColor: "var(--mustard)",
    color: "white",
  },
  "& .csm-status.pendingTest": {
    backgroundColor: "var(--cafe)",
    color: "white",
  },
  "& .csm-status.conversionesManuales": {
    backgroundColor: "var(--blueTwo)",
    color: "white",
  },
  "& .csm-status.nuevosCreativos": {
    backgroundColor: "var(--blueOceanTwo)",
    color: "white",
  },
  "& .csm-status.nuevoTracking": {
    backgroundColor: "var(--greenHoja)",
    color: "white",
  },
  "& .csm-status.nuevaCampania": {
    backgroundColor: "var(--mustardTwo)",
    color: "white",
  },
  "& .csm-status.nuevoCap": {
    backgroundColor: "var(--lila)",
    color: "white",
  },
  "& .csm-status.nuevoRevenue": {
    backgroundColor: "var(--greenGrassTwo)",
    color: "white",
  },
  "& .csm-status.trackingIssues": {
    backgroundColor: "var(--issues)",
    color: "white",
  },
  "& .csm-status.done": {
    backgroundColor: "var(--done)",
    color: "white",
  },
  "& .csm-status.reporteInvalidaciones": {
    backgroundColor: "var(--aqua)",
    color: "white",
  },
  "& .csm-status.globalPostback": {
    backgroundColor: "var(--salmon)",
    color: "white",
  },
  "& .csm-status.hacerSeguimiento": {
    backgroundColor: "var(--pinkTwo)",
    color: "white",
  },
  "& .csm-status.verificandoConCliente": {
    backgroundColor: "rgb(0, 134, 192)",
    color: "white",
  },
  "& .aff-status.reporteDeCalidad": {
    backgroundColor: "var(--greenGrass)",
  },
  "& .aff-status.nuevosFormatos": {
    backgroundColor: "var(--blueTwo)",
  },
  "& .aff-status.nuevosCreativos": {
    backgroundColor: "var(--violetThree)",
  },
  "& .aff-status.discrepancia": {
    backgroundColor: "var(--issues)",
  },

  "& .aff-status.aumentoDeCap": {
    backgroundColor: "var(--cafe)",
  },
  "& .aff-status.solicitudDeFlow": {
    backgroundColor: "var(--pinkThree)",
  },
  "& .aff-status.notTaken": {
    backgroundColor: "var(--blackLow)",
  },
  "& .aff-status.varias": {
    backgroundColor: "var(--orange)",
  },
  "& .aff-status.puntoDeFuga": {
    backgroundColor: "var(--red)",
  },
  "& .aff-status.aumentoDePay": {
    backgroundColor: "var(--blueThree)",
  },
  "& .aff-status.verificarIntegracion": {
    backgroundColor: "var(--salmonTwo)",
  },
  "& .aff-status.reporteInvalidaciones": {
    backgroundColor: "var(--aqua)",
  },
  "& .aff-status.done": {
    backgroundColor: "var(--greenYellow)",
  },
  "& .aff-status.aprobacionDeSubjects": {
    backgroundColor: "var(--greenHoja)",
  },
  "& .aff-status.reactivar": {
    backgroundColor: "var(--paused)",
  },
  "& .aff-status.declinedPending": {
    backgroundColor: "var(--gray)",
  },
  "& .aff-status.segmentacionSugerida": {
    backgroundColor: "var(--brown)",
  },
  "& .aff-status.cambiarPayDeAfiliado": {
    backgroundColor: "var(--salmon)",
  },
  "& .aff-status.flow": {
    backgroundColor: "var(--orangeElectric)",
  },
  "& .super-app.null": {
    backgroundColor: "gray",
  },
  "& .super-app.day": {
    backgroundColor: "gray",
  },
  "& .MuiList-padding": {
    width: "400px",
  },
  "& .pixel-column--cell": {
    backgroundColor: "blue",
  },
};

export const closedBoardStyles = {
  "& .MuiDataGrid-root": {
    border: "1px solid #dee2ff",
  },

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#240046",
    color: "white",
    fontWeight: "bold",
    borderBottom: "1px solid #dee2ff",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
    fontSize: "16px",
  },

  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #dee2ff",
    backgroundColor: "#240046",
    color: "white",
    display: "none",
  },
};

export const getTableStyles = (tableName) => {
  switch (tableName) {
    case "Offer ID":
      return {
        color: "#80ed99",
        border: "8px solid #80ed99",
      };
    case "Inactive":
      return {
        color: "var(--red)",
        border: "8px solid var(--red)",
      };
    case "Not profitable":
      return {
        color: "var(--orange)",
        border: "8px solid var(--orange)",
      };
    case "Pixel Integration":
      return {
        color: "var(--pinkTwo)",
        border: "8px solid var(--pinkTwo)",
      };
    case "Today":
      return {
        color: "rgb(87, 155, 252)",
        border: "8px solid rgb(87, 155, 252)",
      };
    case "Done":
      return {
        color: "#80ed99",
        border: "8px solid #80ed99",
      };
    case "Nuevas campañas":
      return {
        color: "rgb(87, 155, 252)",
        border: "8px solid rgb(87, 155, 252)",
      };
    case "Campañas finalizadas":
      return {
        color: "rgb(0, 200, 117)",
        border: "8px solid rgb(0, 200, 117)",
      };
    case "2024":
      return {
        color: "rgb(102, 204, 255)",
        border: "8px solid rgb(102, 204, 255)",
      };
    case "Previous year":
      return {
        color: "rgb(102, 204, 255)",
        border: "8px solid rgb(102, 204, 255)",
      };
    case "Belén Suero":
      return {
        color: "rgb(128, 128, 128)",
        border: "8px solid rgb(128, 128, 128)",
      };
    case "Maria Camila Arango":
      return {
        color: "rgb(87, 155, 252)",
        border: "8px solid rgb(87, 155, 252)",
      };
    case "Carolina Racana":
      return {
        color: "rgb(162, 93, 220)",
        border: "8px solid rgb(162, 93, 220)",
      };
    case "Katalina Palomino":
      return {
        color: "rgb(255, 21, 138)",
        border: "8px solid rgb(255, 21, 138)",
      };
    case "Sara López Graciano":
      return {
        color: "rgb(156, 211, 38)",
        border: "8px solid rgb(156, 211, 38)",
      };
    case "Afiliado":
      return {
        color: "rgb(87, 155, 252)",
        border: "8px solid rgb(87, 155, 252)",
      };
    case "Nombre de campaña":
      return {
        color: "rgb(87, 155, 252)",
        border: "8px solid rgb(87, 155, 252)",
      };
    case "Tasks":
      return {
        color: "rgb(87, 155, 252)",
        border: "8px solid rgb(87, 155, 252)",
      };
    case "Alerts":
      return {
        color: "rgb(255, 21, 138)",
        border: "8px solid rgb(255, 21, 138)",
      };
    case "Master Tag":
      return {
        color: "rgb(255, 21, 138)",
        border: "8px solid rgb(255, 21, 138)",
      };
    case "Completed":
      return {
        color: "var(--red)",
        border: "8px solid var(--red)",
      };
    case "Prospecting":
      return {
        color: "#80ed99",
        border: "8px solid #80ed99",
      };
    case "crm":
      return {
        color: "#80ed99",
        border: "8px solid #80ed99",
      };
    case "Supply":
      return {
        color: "#80ed99",
        border: "8px solid #80ed99",
      };
    case "Demand":
      return {
        color: "var(--orange)",
        border: "8px solid var(--orange)",
      };

    default:
      return {
        color: "",
        border: "8px solid black",
      };
  }
};

export const alertStyles = {
  "CR mayor 5%": {
    backgroundColor: "rgb(87, 155, 252)",
    color: "white",
  },
  "IPS duplicadas": {
    backgroundColor: "rgb(162, 93, 220)",
    color: "white",
  },
  "Conversiones mismo minuto": {
    backgroundColor: "rgb(187, 51, 84)",
    color: "white",
  },
  "Aumento drástico de clicks": {
    backgroundColor: "rgb(253, 171, 61)",
    color: "white",
  },
  "Click close to conversion": {
    backgroundColor: "rgb(255, 90, 196)",
    color: "white",
  },
  "Muchos clicks sin conversión": {
    backgroundColor: "rgb(255, 100, 46)",
    color: "white",
  },
};

export const criticityStyles = {
  Alta: {
    backgroundColor: "rgb(226, 68, 92)",
    color: "white",
  },
  Media: {
    backgroundColor: "rgb(253, 171, 61)",
    color: "white",
  },
  Baja: {
    backgroundColor: "rgb(0, 200, 117)",
    color: "white",
  },
};

export const hopAlertsType = {
  "Fill rate supply lower than 0.02%": {
    backgroundColor: "rgb(226, 68, 92)",
    color: "white",
  },
  "Fill rate demand lower than 0.02%": {
    backgroundColor: "rgb(253, 171, 61)",
    color: "white",
  },
  "Márgenes menor al 15%": {
    backgroundColor: "rgb(0, 200, 117)",
    color: "white",
  },
  "Win rate menor al 10%": {
    backgroundColor: "rgb(255, 100, 46)",
    color: "white",
  },
  "0 Ad opportunities": {
    backgroundColor: "rgb(87, 155, 252)",
    color: "white",
  },
};

export const modalEditStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1280",
  height: "700px",
  overflowX: "hidden",
  overflowY: "scrolll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  alignItems: "center",
  display: "flex",
  justifyContent: "flex-start",
};
