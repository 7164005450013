import React from 'react';
import { Calendar, User } from 'lucide-react';
import styles from './KanbanCard.module.css';
import Threads from "../../pages/Threads/Threads";

const KanbanCard = ({ task, onDragStart, openTaskDetails }) => {
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, task.id)}

      className={styles.card}
    >
      <div onClick={() => openTaskDetails(task)}>
        <h3 className={styles.taskTitle}>{task.name || `Task ${task.id}`}</h3>
        <p className={styles.taskDescription}>{task.description?.slice(0, 90)}{task.description && task.description.length > 90 ? '...' : ''}</p>
        <p className={styles.taskOwner}>
          <User size={12} className={styles.icon} />
          {task.owner.username}
        </p>
        {task.tags && (
          <div className={styles.tagList}>
            {task.tags.split(',').map((tag, index) => (
              <span key={index} className={styles.tag}>
                {tag.trim()}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={styles.cardFooter}>
        <span className={styles.taskDate}>
          <Calendar size={12} className={styles.icon} />
          {new Date(task.createdAt).toLocaleDateString()}
        </span>
        <span className={styles.threadCount}>
          {/* <MessageSquare size={16} className={styles.conversationIcon} />
          {task.threads.count} */}
          <Threads row={task} />
        </span>
      </div>
    </div>
  );
};

export default KanbanCard;